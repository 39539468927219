export function intersect(selector, callback, ones = false, options = {rootMargin: '-50% 0px -50% 0px',threshold: [0,1]}) {
	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			if (ones && entry.isIntersecting) {
				callback(entry, selector)
				observer.unobserve(entry.target)
			}else{
				callback(entry, selector)
			}
		});
	}, options);
	const elements = document.querySelectorAll(selector)
	if (elements.length) {
		elements.forEach((element) => {
			observer.observe(element)
		})
	}
}


intersect('#services .mobile_service', (e)=>{
	if(window.innerWidth > 991) return
	document.querySelectorAll('#services .mobile_service.show').forEach((el)=>{
		el.classList.remove('show')
	})

	if (e.isIntersecting) {
		e.target.classList.add('show')
	}
})
