import Swiper from "swiper/bundle";
import {updSwiperNumericPagination} from "../swiper";
const blog = new Swiper("#blog .swiper.blog-list", {
	slidesPerView: 1,
	speed: 700,
	spaceBetween: 34.08,
	navigation: {
		nextEl: "#blog .swiper-control.next",
		prevEl: "#blog .swiper-control.prev",
	},
	pagination: {
		el: "#blog .counter"
	},
	on: {
		init: ()=>{
			updSwiperNumericPagination.bind(this)()
		},
		slideChange: updSwiperNumericPagination,
		resize: updSwiperNumericPagination,
	}
})

export function initBlogSwiper() {
	blog.update()
	updSwiperNumericPagination(blog)
	
}
