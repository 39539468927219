import { load } from "./events/load";
import { initBlogSwiper } from "./swipers/blog";
load(() => {
  const posts = [
    ...document.querySelectorAll("#instagram-posts .sbi_photo"),
  ].map((post, id) => {
    const img = post.querySelector("img");
    img.setAttribute("data-index", id);
    return {
      link: post.href,
      img,
    };
  });
  const observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
			if (mutation.type === "attributes") {
				document.querySelector("#blog .blog-list.desktop").classList.remove("mobile-inited");
				[...document.querySelectorAll(".blog-list.mobile .swiper-slide")][mutation.target.getAttribute("data-index")].querySelector("img").src = mutation.target.src;
				document.querySelector("#blog .blog-list.desktop").classList.add("mobile-inited")
      }
    }
  });
  posts.forEach((post) => {
    observer.observe(post.img, { attributes: true, attributeFilter: ["src"] });
    document.querySelector(".blog-list.mobile .swiper-wrapper")?.appendChild(elementFrom(post));
		initBlogSwiper();

  });
  function elementFrom({ link, img }) {
    let imgEl = img.cloneNode(true),
      template = `
			<div class="swiper-slide post">
			<a href="${link}">
			</a>
			</div>
		`,
      pars = new DOMParser().parseFromString(template, "text/html").body
        .firstChild;
    pars.querySelector("a").appendChild(imgEl);
    return pars;
  }
});
